import clsx from "clsx";
import React, {ReactElement} from "react";
import "../styles/components/section_container.sass";

interface Props {
    backgroundElement?: ReactElement;
    children: ReactElement | ReactElement[];
    topGradient?: boolean;
    bottomGradient?: string;
    className?: string;
}

export const SectionContainer = (props: Props) => {
  return (
    <div className={clsx("sectionContainerContainer", props.className)}>
      <div className="backgroundContainer">
        {/* The background element, if any. */}
        {props.backgroundElement ? props.backgroundElement : null}
      </div>

      {props.topGradient ? <div className="topGradient"/> : null}

      <div className={clsx({
          sectionContainer: true,
          // Padding to account for the gradient space.
          sCTopGradient: props.topGradient,
          sCBtmGradient: props.bottomGradient
      })}>
        {props.children}
      </div>

      {props.bottomGradient ? <div className={clsx("bottomGradient", props.bottomGradient)}/> : null}
    </div>
  );
};

export default SectionContainer;
