import React from "react";
import SolutionsIconMessage from "../../assets/solutions/chat_symbol.inline.svg";
import SolutionsIconInfo from "../../assets/solutions/i_in_hexagon.inline.svg";
import "../styles/components/solution_block.sass";
import { LinkOrA } from "./LinkOrA";

interface Props {
  children: React.ReactNode;
  icon?: string;
  title: string;
  learnLink?: string;
  learnLinkTxt?: string;
  contactLink?: string;
  contactLinkTxt?: string;
  cta?: string;
}

export const SolutionBlock = (props: Props) => {
  return (
    <div className="SolutionBlock">
      {props.icon ? <img src={props.icon} alt="" /> : ""}
      <h3 className="SolutionBlock__Title">{props.title}</h3>
      <div className="SolutionBlock__Content">{props.children}</div>
      <div className="SolutionBlock__ButtonGroup">
        {props.contactLink ? (
          <LinkOrA
            to={props.contactLink}
            state={{ cta: props.cta }}
            className="SolutionBlock__Button"
          >
            <SolutionsIconMessage />
            <span className="SolutionBlock__ButtonText">
              {props.contactLinkTxt}
            </span>
          </LinkOrA>
        ) : (
          ""
        )}

        {props.learnLink ? (
          <LinkOrA
            to={props.learnLink}
            className="SolutionBlock__Button SolutionBlock__Button--NoBg"
          >
            <span className="SolutionBlock__ButtonText">
              {props.learnLinkTxt}
            </span>
            <SolutionsIconInfo />
          </LinkOrA>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SolutionBlock;
