import React from "react";

function CarouselButton(cls: string) {
    return <span aria-hidden="true" className={cls}/>;
}
export function CarouselRightButton() {
    return CarouselButton("carousel-control-next-icon carouselButton");
}

export function CarouselLeftButton() {
    return CarouselButton("carousel-control-prev-icon carouselButton");
}
