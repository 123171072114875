import {SpecregexThroughputCharts} from "./SpecregexThroughputChart";
import React from "react";
import ReactTooltip from 'react-tooltip';
import {SpecregexLatencyCharts} from "./SpecregexLatencyChart";

const RegexPerformanceGizmo = () => {
    return <div className="featureEpilogue">
        <h2>Highest Throughput</h2>
        <ReactTooltip />

        This chart shows the throughput attained when finding all matches (with capture groups) of various regexes on
        a snapshot of all of <a href="https://en.wikinews.org/wiki/Main_Page">Wikinews</a>.<br/>
        <br/>
        Note: Hyperscan is included even though it does not actually output the capture groups, rendering the test
        slightly unfair in their favour.<br/>
        <br/>
        GPU measurements performed on an NVIDIA GTX 1080.

        <SpecregexThroughputCharts
            regexes={[{
                dataName: "Presidents (search)",
                label: "Presidents",
                regex: "[Pp]resident ([^,.:;!]+) said"
            }, {
                dataName: "According to (search)",
                label: "According-to",
                regex: "[Aa]ccording to ([^,]+),"
            }, {
                dataName: "Hyphenated words (search)",
                label: "Hyphenated words",
                regex: "([A-Z]?[a-z]+)-([A-Z]?[a-z]+)"
            }, {
                dataName: "Dates (search)",
                label: "Dates",
                regex: "([123]?[0-9])(?:st|nd|rd|th)? (Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?) ((?:19|20)[0-9]{2})"
            }]}
        />
        <h2>Lowest Latency</h2>

        For some applications, the constant overhead of running our GPU implementation is just too much. Specregex-CPU
        provides the lowest possible match latencies - even for very small inputs - because we generate custom code
        for each regex.<br/>
        <br/>
        This chart shows the latency of performing the same search operation on random tweets.

        <SpecregexLatencyCharts
            regexes={[{
                dataName: "Hashtag (search)",
                label: "Hashtag",
                regex: "#([A-Za-z0-9_.-]+)"
            }, {
                dataName: "Retweet (search)",
                label: "Retweet",
                regex: "RT @([A-Za-z0-9_.-]+)"
            }, {
                dataName: "Trump (search)",
                label: "Trump",
                regex: "[Tt]rump"
            }, {
                dataName: "Combined (search)",
                label: "Combined",
                regex: "All of the regexes to the left at once"
            }, {
                dataName: "URL (search)",
                label: "URL",
                regex: "([a-z]+)://([a-z0-9_.-]+)(:[0-9]+)?([A-Za-z0-9_.%#/?-]*)"
            }]}
        />
    </div>;
};
export default RegexPerformanceGizmo;
