import SectionContainer from "../../components/sectionContainer";
import Carousel from "react-bootstrap/Carousel";
import CarouselItem from "react-bootstrap/CarouselItem";
import {decomposeIntoSections, mkRootNode, renderAst} from "../common";
import {ReferralsItem} from "../../components/referrals";
import React from "react";
import {CarouselLeftButton, CarouselRightButton} from "../../components/Carousel";
import CircuitBG from "../../../assets/solutions/solutions_bg.svg";

export function renderTestimonialSection(testes: any, key: React.Key) {
    const quotes = decomposeIntoSections(testes, "h3");

    const c: any[] = testes.children;

    // Content that goes before the first header.
    const prefix = mkRootNode(c.slice(0, c.findIndex(x => x.tagName == "h3")));

    return (
        <SectionContainer key={"Testimonials" + key} className="referralsSection" backgroundElement={<img alt="" src={CircuitBG}/>}>
            <div className="preTestContent">
            {renderAst(prefix)}
            </div>
            <Carousel
                controls={quotes.length > 1}
                keyboard={false}
                interval={null}
                indicators={false}
                prevIcon={<CarouselLeftButton/>}
                nextIcon={<CarouselRightButton/>}
                style={{width: "100%"}}
            >
                {quotes.map(e => {
                    const [company, payload] = e;

                    // Extract the individual quotee name.
                    const payloadChildren = payload.children[1].children;
                    const lastList = payloadChildren.findIndex((c: any) => c.tagName == "ul");

                    const personName = payloadChildren[lastList].children[1].children[0].value;

                    const pruned = mkRootNode(payloadChildren.slice(0, lastList));

                    return (
                        <CarouselItem key={personName}>
                            <ReferralsItem name={personName} company={company}>
                                {renderAst(pruned)}
                            </ReferralsItem>
                        </CarouselItem>
                    );
                })}
            </Carousel>
        </SectionContainer>
    );
}
