import React from "react";
import {Link} from "gatsby";
import SnakeIcon from '../../assets/call2a_snake.inline.svg';
import "../styles/components/carousel_theres_more.sass";

export const CarouselTheresMore = () => {
  return (
  <div className="floatyPositionBox">
    <SnakeIcon className="floatyIcon ucfloaty"/>
    <Link to="#more" className="cfloaty">
      <SnakeIcon className="floatyIcon"/>
    </Link>
  </div>
  );
};

export default CarouselTheresMore;
