import React from "react";

import data from "../content/datasets/specregex-latency.json";
import {ChartData, ChartOptions} from "chart.js";
import GraphWidget from "./GraphWidget";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import 'react-tabs/style/react-tabs.css';

interface RegexCfg {
    dataName: string;
    label: string;
    regex: string;
}
interface SpecregexLatencyChartProps {
    regex: RegexCfg;
}
export const SpecregexLatencyChart = (props: SpecregexLatencyChartProps) => {
    // Generate graphable data...
    const chunk: any = data.testRegexes.find((v) => v.testName == props.regex.dataName);

    // Transform it out of "Nick-insanity-format"
    const libraries = [
        chunk.referenceLibrary,
        ...chunk.testLibraries
    ];

    const chartData: ChartData<"bar"> = {
        labels: libraries.map((l: any) => l.engineName),
        datasets: [{
            label: "Latency (μs)",
            data: libraries.map((l: any) => l.timings.nsTotalTime / 1000),
        }]
    };
    const opts: ChartOptions<"bar"> = {
        responsive: true,
        indexAxis: 'y',
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Latency (μs)",
                    color: "#71AEf4"
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
            // title: {
            //     display: true,
            //     text: props.title
            // },
        }
    };

    return <GraphWidget
        data={chartData}
        options={opts}
    />;
};

export const SpecregexLatencyCharts = (props: {regexes: RegexCfg[]}) => {
    return <Tabs className="perfTabs">
        <TabList>
            {props.regexes.map((r) => <Tab data-tip={r.regex} key={r.label}>{r.label}</Tab>)}
        </TabList>

        {props.regexes.map(r => <TabPanel key={r.label}>
            <SpecregexLatencyChart regex={r}/>
        </TabPanel>)}
    </Tabs>;
};
