import {Grid, IconButton} from "@material-ui/core";
import React, {ReactElement} from "react";
import {renderAst} from "../common";
import Call2AIconPhone from '../../../assets/contact/call2a_phone.inline.svg';
import Call2AIconEmail from '../../../assets/contact/call2a_email.inline.svg';
import Call2AIconChat from '../../../assets/contact/call2a_chat.inline.svg';
import '../../styles/components/contactcta.sass';
import {EMAIL_ADDRESS, PHONE_NUMBER} from "../../Constants";

export function renderContactCTABanner(content: any, key: React.Key) {
    const ICON_CFG: [ReactElement, string][] = [
        [<Call2AIconPhone key="phone"/>, `tel:${PHONE_NUMBER}`],
        [<Call2AIconEmail key="email"/>, `mailto:${EMAIL_ADDRESS}`],
        [<Call2AIconChat key="chat"/>, "#contact"]
    ];

    return <div key={"CtaBanner" + key} className="pushSection">
        <div className="pushSectionBackground">
            <Grid container direction="column" justifyContent="space-evenly" className="pushSectionGrid">
                <Grid item>
                    <h2>Contact us now</h2>
                </Grid>
                <Grid item>
                    <div className="markdownContent">
                        {renderAst(content)}
                    </div>
                </Grid>
                <Grid item>
                    <Grid container justifyContent="center">{
                        ICON_CFG.map((p, i) => {
                             const [element, link] = p;
                             return (
                                <Grid item key={i}>
                                    <IconButton
                                        disableRipple className={"ctaButtonOverlay ctaButtonOverlay_" + i}
                                        href={link}
                                    >
                                        {element}
                                    </IconButton>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>;
}
