import React from "react";
import SectionContainer from "./sectionContainer";
import AIBG from "../../assets/carousel/ai.svg";
import CompilerBG from "../../assets/carousel/compiler.svg";
import RiseBG from "../../assets/carousel/rise.svg";
import RegexBG from "../../assets/carousel/regex.svg";

import "../styles/components/about.sass";

function getBg(path: React.Key) {
    const k = path.toString();
    console.log(k);
    if (k.startsWith("/ai")) {
        return AIBG;
    } else if (k.startsWith("/regex")) {
        return RegexBG;
    } else if (k.startsWith("/rise")) {
        return RiseBG;
    } else if (k.startsWith("/compiler")) {
        return CompilerBG;
    }
    return RiseBG;
}

export const AboutSection = (props: {children: React.ReactNode, path: React.Key}) => {
  return (
    <SectionContainer backgroundElement={<img className="aboutBg" src={getBg(props.path)}/>} className="aboutSection">
        <div className="aboutContent">
        {/* Markdown injection site*/}
        {props.children}
      </div>
    </SectionContainer>
  );
};

export default AboutSection;
