import React from "react";

export const ScrollAnchor = ({id}: {id: string}) => {

  return (
    <div id={id} className="scrollAnchor"/>
  );
};

export default ScrollAnchor;
