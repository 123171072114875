import React from "react";
import palette from "google-palette";
import {Bar} from 'react-chartjs-2';

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip, ChartData, ChartOptions
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);
Chart.defaults.color = '#E2E2E2';

function convertCol(str: string) {
    const out: number[] = [];
    for (let i = 0; i < 6; i += 2) {
        out.push(parseInt(str.slice(i, i + 2), 16));
    }
    return out;
}

function toRGBA(bytes: number[], alpha: number) {
    return `rgba(${bytes[0]}, ${bytes[1]}, ${bytes[2]}, ${alpha})`;
}

const PRETTY_ENGINE_NAME: {[name: string]: string} = {
    'pcre-jit-sequential': "PCRE",
    're2': "RE2",
    'specregex-gpu-sync': "SpecRegex (GPU)",
    'specregex-cpu': "SpecRegex (CPU)",
    'hyperscan-sequential': "Hyperscan",
};

// colorizes the dataset
const buildDataColor = (data: ChartData<any>): [string[], ChartData<any>] => {
    const datasets = data.datasets;
    let cnt = 0;
    for (const ds of datasets) {
        for (const _ of ds.data) {
            cnt++;
        }
    }

    data.labels = data.labels!.map((l: any) => PRETTY_ENGINE_NAME[l]);

    const colours = palette(['tol', 'qualitative'], cnt).map(convertCol);

    let ctr = 0;
    for (const ds of datasets) {
        ds.borderColor = [];
        ds.backgroundColor = [];
        ds.borderWidth = 2;
        for (const _ of ds.data) {
            const col = colours[ctr];
            ds.borderColor.push(toRGBA(col, 1));
            ds.backgroundColor.push(toRGBA(col, 0.5));
            ctr++;
        }
    }

    return [colours, data];
};

interface GraphWidgetProps {
    data: ChartData<"bar">;
    options: ChartOptions<"bar">;
}

export const GraphWidget = (props: GraphWidgetProps) => {
    const [colours, data] = buildDataColor(props.data);

    const options = props.options;
    options.scales!.x = {
        grid: {
            display: true,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
            color: '#FFFFFF66'
        },
        ...options.scales?.x
    };
    options.scales!.y = {
        grid: {
            display: true,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
            color: '#FFFFFF22'
        },
        ...options.scales?.y
    };

    return (
        <Bar width="400" height={20 + (colours.length * 15)} data={data} options={options} />
    );
};

export default GraphWidget;
