import AboutSection from "../../components/about";
import {renderAst} from "../common";
import React from "react";
import CarouselTheresMore from "../../components/carouselTheresMore";
import ScrollAnchor from "../../components/scrollAnchor";

export function renderSummarySection(content: any, key: React.Key) {
    return <><AboutSection path={key} key={"Summary" + key}>
        {renderAst(content)}
    </AboutSection>
    <CarouselTheresMore/>
    <ScrollAnchor id="more"/>
    </>;
}
