import React from "react";
import CircuitBG from "../../../assets/solutions/solutions_bg.svg";
import ScrollAnchor from "../../components/scrollAnchor";
import SectionContainer from "../../components/sectionContainer";
import SolutionBlock from "../../components/solutionBlock";
import {decomposeIntoSections, renderAst} from "../common";

export function renderSolutionsSection(features: any, key: React.Key) {
  const subFeatures = decomposeIntoSections(features, "h3");

  return (
    <SectionContainer
      key={"Sln" + key}
      backgroundElement={<img alt="" src={CircuitBG} />}
    >
      <ScrollAnchor id="solutions" key={"SlnAnchor" + key} />
      <div className="Solutions">
        {subFeatures.map((e) => {
          const k = e[0];
          const v = JSON.parse(JSON.stringify(e[1]));

          const c = v.children;

          const links = c[c.length - 2].children;



          const contactLink = links?.[0];
          const contactLinkAddr = contactLink?.properties?.href;
          const contactLinkLabel = contactLink?.children?.[0]?.value;

          if (contactLinkAddr && contactLinkLabel) {
            links.splice(0, 2);
          }

          const implLink = links?.[0];
          const implLinkAddr = implLink?.properties?.href;
          const implLinkLabel = implLink?.children?.[0]?.value;

          if (implLinkAddr && implLinkLabel) {
            links.splice(0, 1); // Two deletions in order to eat the synthesised newline, too.
          }

          return (
            <SolutionBlock
              key={k}
              learnLink={implLinkAddr}
              learnLinkTxt={implLinkLabel}
              contactLink={contactLinkAddr}
              contactLinkTxt={contactLinkLabel}
              title={k}
            >
              {renderAst(v)}
            </SolutionBlock>
          );
        })}
      </div>
    </SectionContainer>
  );
}
