import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import {renderFeatureSection} from "./sections/Features";
import {decomposeIntoSections, extractTitle} from "./common";
import {renderSummarySection} from "./sections/Summary";
import {renderContactCTABanner} from "./sections/ContactCTA";
import {renderTestimonialSection} from "./sections/Testimonials";
import {renderContactSection} from "./sections/Contact";
import {renderSolutionsSection} from "./sections/Solutions";
import PageHelmet from "../components/PageHelmet";
import {renderPlainContentSection} from "./sections/PlainContent";

function renderSection(sectionName: string,
                       sectionContent: any,
                       nextSectionName: string,
                       currentPath: string) {
  switch (sectionName) {
    case "Content":
      return renderPlainContentSection(sectionContent, currentPath);
    case "Summary":
      return renderSummarySection(sectionContent, currentPath);
    case "Contact":
      return renderContactSection(sectionContent, currentPath);
    case "Features":
      return renderFeatureSection(sectionContent, nextSectionName, currentPath);
    case "Testimonials":
      return renderTestimonialSection(sectionContent, currentPath);
    case "Solutions":
      return renderSolutionsSection(sectionContent, currentPath);
    case "Contact CTA Banner":
      return renderContactCTABanner(sectionContent, currentPath);
    default:
      throw new Error("Whoops");
  }
}

export default function Template(props: any) {
  const ast = props.data.markdownRemark.htmlAst;
  const frontmatter = props.data.markdownRemark.frontmatter;

  // Canoncialise path to account for differences in representation in SSR/browser :D
  let effPath = props.location.pathname;
  if (effPath.endsWith("/") && effPath.length > 1) {
    effPath = effPath.slice(0, effPath.lastIndexOf("/"));
  }

  // Convert AST into a map from section name to list of AST elements that form that section. We then can have special
  // rules for how to render each one.
  const sections = decomposeIntoSections(ast);
  const title = extractTitle(ast);

  return (
    <Layout route={effPath}>
      <PageHelmet title={title} description={frontmatter?.GoogleSummary}/>
      {/* The rest of the page is made up of sections implied by the markdown sections */}
      {sections.map((e, key) => {
        const [sectionName, sectionContent] = e;
        let nextSection = undefined;
        if (key < sections.length - 1) {
          nextSection = sections[key + 1][0];
        }

        return renderSection(sectionName, sectionContent, nextSection ?? "", effPath);
      })}
    </Layout>
  );
}

export const pageQuery = graphql`
  query ProductPages($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        GoogleSummary
      }
    }
  }
`;
