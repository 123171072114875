import React from "react";
import BinaryPattern from "../../components/binaryPattern";
import { LinkOrA } from "../../components/LinkOrA";
import SectionContainer from "../../components/sectionContainer";
import "../../styles/components/features.scss";
import {decomposeIntoSections, renderAst} from "../common";
import RegexPerformanceGizmo from "../../components/RegexPerformanceGizmo";

interface ParsedFeatureBox {
    header: any;
    content: any;
    footer: any;
}

function renderPrologueEpilogue(section: ParsedFeatureBox) {
    return (
        <div className="featureEpilogue">
            {section.header}
            {section.content}
            {section.footer}
        </div>
    );
}

function parseFeatureBox(e: [string, any]) {
    const k = e[0];
    const v = JSON.parse(JSON.stringify(e[1]));

    const c = v.children;

    const links = c[c.length - 2].children;
    let numLinks = 0;

    const popLink = () => {
        let addr;
        let label;
        if (links?.[0] && links[0].tagName == "a") {
            addr = links[0]?.properties?.href;
            label = links[0]?.children?.[0]?.value;

            if (addr && label) {
                numLinks++;
                links.splice(0, 2);
                return [label, addr];
            }
        }

        return [undefined, undefined];
    };

    const [leftLinkLabel, leftLinkAddr] = popLink();
    const [rightLinkLabel, rightLinkAddr] = popLink();

    return {
        header: <h2>{k}</h2>,
        footer: (leftLinkLabel || rightLinkLabel) ? <div className="bottomBox">
            {leftLinkLabel ? (
                <LinkOrA
                    to={leftLinkAddr}
                    className={`leftButton leftButton_${numLinks}`}
                >
                    {/*<SolutionsIconMessage/>*/}
                    <span className="buttonCaption">
                            {leftLinkLabel}
                        </span>
                </LinkOrA>
            ) : (
                ""
            )}

            {rightLinkLabel ? (
                <LinkOrA
                    to={rightLinkAddr}
                    className={`bottomButton bottomButton_${numLinks}`}
                >
                        <span className="buttonCaption">
                            {rightLinkLabel}
                        </span>
                    {/*<SolutionsIconInfo/>*/}
                </LinkOrA>
            ) : (
                ""
            )}
        </div> : null,
        content: renderAst(v)
    };
}



export function renderFeatureSection(
    features: any,
    nextSectionName: string,
    currentPath: React.Key
) {
    const subfeatures = decomposeIntoSections(features, "h3");

    // Prolog-epilogue inserter.
    const prologues: any[] = [];
    while (true) {
        const prologueIdx = subfeatures.findIndex((x) => x[0].startsWith("Prologue"));
        if (prologueIdx != -1) {
            const p = subfeatures.splice(prologueIdx, 1)[0];
            if (p[0].startsWith("Prologue:")) {
                p[0] = p[0].slice("Prologue: ".length);
            }
            prologues.push(renderPrologueEpilogue(parseFeatureBox(p)));
        } else {
            break;
        }
    }

    let epilogues: any[] = [];
    while (true) {
        const epilogueIdx = subfeatures.findIndex((x) => x[0].startsWith("Epilogue"));
        if (epilogueIdx != -1) {
            const p = subfeatures.splice(epilogueIdx, 1)[0];
            if (p[0].startsWith("Epilogue:")) {
                p[0] = p[0].slice("Epilogue: ".length);
            }
            epilogues.push(renderPrologueEpilogue(parseFeatureBox(p)));
        } else {
            break;
        }
    }

    // This is insane but I'm going to do it anyway.
    // TODO: This can now be removed if we make the "custom react in markdown" plugin actually work!
    if (currentPath == "/regex") {
        epilogues = [<RegexPerformanceGizmo key="0"/>];
    }

    // The bottom gradient is tweaked based on the identity of the following section.
    let bottomGradient = "bottomGradientCTA"; // Default.
    if (nextSectionName == "Testimonials") {
        bottomGradient = "bottomGradientReferrals";
    }

    const parsed: ParsedFeatureBox[] = [];
    for (const e of subfeatures) {
        parsed.push(parseFeatureBox(e));
    }

    return (
        <SectionContainer
            backgroundElement={<BinaryPattern />}
            topGradient
            bottomGradient={bottomGradient}
            key={"Features" + currentPath}
        >
            <>{prologues.map(x => <div className="journeyBox" key={"hi"}>{x}</div>)}</>
            <div className="featureGrid">
                {parsed.map((e, i) => (
                    <div key={i} className="fgridElement">
                        <div className="content">
                            {e.header}
                            {e.content.props.children /* HACK: to make styling work */}
                        </div>
                        <div className="fGridFooter">{e.footer}</div>
                    </div>
                ))}
            </div>
            <>{epilogues.map(x => <div className="journeyBox" key={"hi"}>{x}</div>)}</>
        </SectionContainer>
    );
}
