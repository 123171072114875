import {Link} from "gatsby";
import React from "react";

interface LinkOrAProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    to: string;
    state?: any;
}
// Renders either a <Link> or an <a>, depending on whether `to` is an external link or not.
export function LinkOrA(props: LinkOrAProps) {
    if (props.to.startsWith("http")) {
        return <a href={props.to} {...props}>
            {props.children}
        </a>;
    } else {
        return <Link {...props}>
            {props.children}
        </Link>;
    }
}
