import React from 'react';
import {TextField, Button, CircularProgress, Grid} from "@material-ui/core";
import SectionContainer from "./sectionContainer";
import Call2AIconMail from "../../assets/contact/call2a_mail.inline.svg";
import Call2AIconPhone from "../../assets/contact/call2a_phone.inline.svg";
import Call2AIconEmail from "../../assets/contact/call2a_email.inline.svg";
import "../styles/components/contact.sass";
import {StandardTextFieldProps} from "@material-ui/core/TextField/TextField";
import {useAsync} from "react-async";
import {EMAIL_ADDRESS, PHONE_NUMBER, PRETTY_PHONE_NUMBER} from "../Constants";

interface InputRowProps extends StandardTextFieldProps {
  setter: (e: any) => void;
}
const InputRow: React.FC<InputRowProps> = ({setter, ...restProps}) => {
  return (
    <TextField
      fullWidth
      onChange={e => setter(e.currentTarget.value)}
      className="inputRow"
      {...restProps}
      InputProps={{
        disableUnderline: true,
        ...(restProps.InputProps ?? {}),
      }}
    />
  );
};

interface ContactGridProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  href?: string;
}
const ContactGrid = (props: ContactGridProps) => {
  return (
    <Grid item container
          justifyContent="flex-start"
          alignItems="center"
    >
        <Grid item className="contactImage contactItem" xs={12} sm={4} md={2} lg={4}>
          <a href={props.href ?? ""}>
            {props.icon}
          </a>
        </Grid>
        <Grid item xs={12} sm={8} md={10} lg={8} className="contactLabel contactItem">
          <a href={props.href ?? ""}>
            <span className="contactText">{props.children}</span>
          </a>
        </Grid>
    </Grid>
  );
};

interface Props {
  path: string; // Page we're currently on.
  cta?: string;
  children?: React.ReactNode;
}

export const Contact = (props: Props) => {
  // Set up the state
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [organisation, setOrganisation] = React.useState("");
  const [text, setText] = React.useState("");
  const [submitMessage, setSubmitMessage] = React.useState("");

  const emailFieldAndChildren = () => {
    return document.querySelectorAll("#emailField *");
  };

  const setEmailWrapper = (val: string) => {
    val = val.trim();
    emailFieldAndChildren().forEach((el) => el.classList.remove("invalid"));
    setEmail(val);
  };

  const emailRe = /.+@.+/;

  const checkEmail = () => {
    const ok = emailRe.test(email);
    if (!ok) {
      emailFieldAndChildren().forEach((el) => {
        el.classList.add("invalid");
      });
    }
    return ok;
  };

  const submitContactForm = async() => {
    if(!checkEmail()) {
      return;
    }

    setLoading(true);
    let result;
    try {
      result = await (await fetch("https://api.spectralcompute.co.uk/contact", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          email: email,
          company: organisation,
          message: text,
          page: props.path
        })
      })).json();
    } catch (e) {
      console.log(e);
    }

    if (result?.status == "OK") {
      setSubmitMessage("Thanks for reaching out. You'll hear back from us.");
    } else {
      setSubmitMessage("It seems the contact form is currently on fire. This is awkward. Please send us an email :D");
    }
    setLoading(false);
  };
  const doContactFormSubmit = useAsync<void>({deferFn: async() => submitContactForm()});

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    doContactFormSubmit.run();
  };

  return (
    <SectionContainer className="contactSection"><>
      {props.children ?? <h3>We'd love to hear from you.</h3>}

      <Grid container className="contactForm" direction={"row"} wrap={"wrap"}>
        <Grid item className="inputContainer" xs={12} lg={6}>
          {loading ? <CircularProgress/> : null}
          {submitMessage ? <h3>{submitMessage}</h3> :
            <form onSubmit={onSubmit} id="contactForm">
              <InputRow InputProps={{['aria-label']: "name"}} autoComplete="name" placeholder="Name (required)" setter={setName} required/>
              <div id="emailField">
                <InputRow InputProps={{['aria-label']: "email"}} autoComplete="email" inputProps={{title: "email address"}} placeholder="Email (required)" setter={setEmailWrapper} onBlur={checkEmail} required/>
              </div>
              <InputRow InputProps={{['aria-label']: "company"}} autoComplete="organization" placeholder="Organisation" setter={setOrganisation} required={false}/>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  ['aria-label']: "message"
                }}
                required
                fullWidth
                placeholder="What can we do for you? (required)"
                multiline
                rows={10}
                onChange={e => setText(e.currentTarget.value)}
                className="inputBox"
              />
              <Button fullWidth disableRipple color="primary" type="submit" className="inputButton">
                Submit
              </Button>
            </form>
          }
        </Grid>

        <Grid item container xs={12} lg={4} direction="column" justifyContent="space-evenly" className="contactContainer">
          <ContactGrid icon={<Call2AIconMail/>}>
            483 Green Lanes, London<br/>
            England<br/>
            N13 4BS
          </ContactGrid>
          <ContactGrid icon={<Call2AIconPhone/>} href={`tel:${PHONE_NUMBER}`}>
            {PRETTY_PHONE_NUMBER}
          </ContactGrid>
          <ContactGrid icon={<Call2AIconEmail/>} href={`mailto:${EMAIL_ADDRESS}`}>
            {EMAIL_ADDRESS}
          </ContactGrid>
        </Grid>
      </Grid></>
    </SectionContainer>
  );
};

export default Contact;
