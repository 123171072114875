import ScrollAnchor from "../../components/scrollAnchor";
import Contact from "../../components/contact";
import {renderAst} from "../common";
import React from "react";

export function renderContactSection(content: any, key: string) {
    return <>
        <ScrollAnchor id="contact" key={key + "anchor"}/>
        <Contact path={"Contact" + key} key={key + "Contact"}>
            {renderAst(content)}
        </Contact>
    </>;
}
