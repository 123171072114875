import React from "react";
import "../styles/components/referrals.sass";

interface Props {
    children: React.ReactNode;
    name: string;
    company: string;
}

export const ReferralsItem = (props: Props) => {
  return (
    <div className="referrals">
      <div className="message">
        {/* Markdown injection */}
        {props.children}
      </div>
      <div className="bottom">
        <div className="person">
          {props.name}
        </div>
        <div className="company">
          {props.company}
        </div>
      </div>
    </div>
  );
};
