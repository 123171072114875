import React from "react";
import CircuitBG from "../../../assets/solutions/solutions_bg.svg";
import SectionContainer from "../../components/sectionContainer";
import {renderAst} from "../common";

export function renderPlainContentSection(features: any, key: React.Key) {
  return (
    <SectionContainer
      className="plain"
      key={key}
      backgroundElement={<img alt="" src={CircuitBG} />}
    >
      <div className={`markdownContent`}>
        {renderAst(features)}
      </div>
    </SectionContainer>
  );
}
